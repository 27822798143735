.editContainer {
  padding: 24px 16px;
}

.addBtn {
  color: #4867b1;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.branchBox {
  border: 1px dashed lightgray;
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin-bottom: 15px;
}

.impBox {
  padding: 12px 12px;
  border: 1px solid lightgray;
  border-radius: 10px;
}
